import { Routes, Route, Navigate } from 'react-router-dom';
import { ProtectedRoutesWrapper, RouteGuard } from 'shared-components';
import {
  ClientEngagementAgreement,
  CsServicesChangeDeposit,
  CustomerService,
  DedicatedAccount,
  Disclosures,
  EnrolledDebt,
  EnrolledDebtDetail,
  Home,
  NegotiationOverview,
  NotFound,
  Notifications,
  Settings,
  SettlementOverview
} from 'shared-pages/dashboard/lazy';
import {
  AccountStatements,
  BankAccountChange,
  CollectionLetters,
  Deposits,
  EnrollNewDebt,
  IncreaseProgramDeposit,
  LegalDocuments,
  MiscellaneousDocuments,
  OneTimeDeposit,
  PastDueNotices,
  SelectDocType
} from 'shared-pages/dashboard/CustomerService';
import {
  EmptyContent,
  NotificationContent,
  NotificationsList
} from 'shared-pages/dashboard/Notifications';
import { ErrorFallback } from 'shared-pages/common/lazy';
import { LoginError } from 'shared-pages/common';

import { AutoSettlementAuth } from 'shared-components/OneClickAuthorization/AutoSettlementAuth';
import { AutoDepositAuth } from 'shared-components/OneClickAuthorization/AutoDepositAuth';

import {
  Main as ProgramResources,
  CreditorCalls,
  FAQPage,
  HelpfulDocuments,
  HowItWorks,
  ProgramGlossary
} from 'shared-pages/dashboard/ProgramResources';

export function TLGRoutes() {
  return (
    <Routes>
      {/**** RESTRICTED ROUTES ****/}
      <Route
        element={<RouteGuard wrapper={ProtectedRoutesWrapper} isDashboard />}
      >
        <Route path="/" element={<Home />} />
        <Route path="/customer-service" element={<CustomerService />}>
          <Route path="bank-account-change" element={<BankAccountChange />} />
          <Route path="deposits" element={<Deposits />}></Route>
          <Route
            path="deposits/change-deposit/*"
            element={<CsServicesChangeDeposit />}
          />
          <Route
            path="deposits/increase-program-deposit"
            element={<IncreaseProgramDeposit />}
          />
          <Route
            path="deposits/one-time-deposit"
            element={<OneTimeDeposit close />}
          />
          <Route path="document-upload" element={<SelectDocType />} />
          <Route
            path="document-upload/account-statements"
            element={<AccountStatements />}
          />
          <Route
            path="document-upload/past-due-notices"
            element={<PastDueNotices />}
          />
          <Route
            path="document-upload/collection-letters"
            element={<CollectionLetters />}
          />
          <Route
            path="document-upload/legal-documents"
            element={<LegalDocuments />}
          />
          <Route
            path="document-upload/miscellaneous-documents"
            element={<MiscellaneousDocuments />}
          />
          <Route path="enroll-new-debt" element={<EnrollNewDebt />} />
        </Route>
        <Route path="/dedicated-account" element={<DedicatedAccount />} />
        <Route path="/enrolled-debt">
          <Route path="" element={<EnrolledDebt />} />
          <Route path=":accountTag" element={<EnrolledDebtDetail />} />
          <Route
            path="negotiation-overview"
            element={<NegotiationOverview />}
          />
          <Route
            path="negotiation-overview/:sub"
            element={<NegotiationOverview />}
          />
          <Route path="settlement-overview" element={<SettlementOverview />} />
        </Route>
        <Route path="/notifications" element={<Notifications />}>
          <Route
            path=""
            element={<Navigate to="/notifications/alerts" replace />}
          />
          <Route path=":notificationType" element={<NotificationsList />}>
            <Route path=":referenceId" element={<NotificationContent />} />
            <Route path="" element={<EmptyContent />} />
          </Route>
        </Route>
        <Route path="/program-resources">
          <Route path="" element={<ProgramResources />} />
          <Route
            path="creditor-calls"
            element={<CreditorCalls pageUrl="creditor-calls" />}
          />
          <Route path="faq" element={<FAQPage pageUrl="faq" />} />
          <Route
            path="helpful-documents"
            element={<HelpfulDocuments pageUrl="helpful-documents" />}
          />
          <Route
            path="how-it-works"
            element={<HowItWorks pageUrl="how-it-works" />}
          />
          <Route path="program-glossary" element={<ProgramGlossary />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/settings">
          <Route path="" element={<Settings />} />
          <Route path=":sub" element={<Settings />} />
        </Route>
      </Route>
      {/**** OPEN ROUTES ****/}
      <Route path="/cea/:lawFirmId" element={<ClientEngagementAgreement />} />
      <Route
        path="/cea-generic/:lawFirmId"
        element={
          <ClientEngagementAgreement
            ceaUrl={'client-engagement-agreement-generic'}
          />
        }
      />
      <Route path="/disclosures" element={<Disclosures />} />
      <Route path="/login-error" element={<LoginError />} />
      <Route path="/page-error" element={<ErrorFallback />} />
      {/**** EMAIL AUTHORIZATION / ONE-CLICK ROUTES ****/}
      <Route
        path="/deposit-authorization/:alertId"
        element={<AutoDepositAuth />}
      />
      <Route
        path="/settlement-authorization/:settlementCode"
        element={<AutoSettlementAuth />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
