import React from 'react';
import { Typography } from '@achieve/sunbeam';
import { useLaunchDarkly, useTranslation } from 'lib/hooks';
import { getAppSubDomain } from 'shared-components/ProvideDashboardType';

import styles from './ProgramDetailsFooter.module.scss';

export const ProgramDetailsFooter = () => {
  const { t } = useTranslation();

  return (
    <section className={styles.programDetailsFooter}>
      <Typography variant="ascendBodyXs">
        <ProgramDetailsDisclaimerParagraphs />
        <div data-testid="program-details-footer-list">
          <ol>
            <li>{t('programDetails.estimatedCurrentTotalMinimumMonthly')}</li>
            <li>{t('programDetails.timeToPayDownYourDebtsIsTheTotal')}</li>
            <li>{t('programDetails.totalEstimatedAmountYouWillPay')}</li>
          </ol>
        </div>
      </Typography>
    </section>
  );
};

const ProgramDetailsDisclaimerParagraphs = () => {
  const { t } = useTranslation();
  const { activeFlagNames, flags } = useLaunchDarkly();

  const subdomain = getAppSubDomain();
  const isTurnbull = subdomain === 'turnbull';

  // feature flag to update the copy of the second disclosures paragraph (graph2Content)
  const displayUpdatedDisclosures =
    flags[activeFlagNames.cfosDisclosuresUpdate] === 'showUpdatedDisclosures';

  // The copy is different in paragraph 1 between TLG and the other brands
  const graph1Content = isTurnbull
    ? t('programDetails.theseEstimatesAreNotActualAmountsTLG')
    : t('programDetails.theseEstimatesAreNotActualAmounts');

  let graph2Content;

  // Updated copy is the same for all 3 brands after feature release
  if (displayUpdatedDisclosures) {
    graph2Content = t('programDetails.theseEstimatesAssumeThatThereAre');
  } else {
    graph2Content = isTurnbull
      ? t('programDetails.theseEstimatesAssumeThatThereAreOldCopyTLG')
      : t('programDetails.theseEstimatesAssumeThatThereAreOldCopy');
  }

  return (
    <div data-testid="program-details-footer-paragraphs">
      <p>{graph1Content}</p>
      <p>{graph2Content}</p>
    </div>
  );
};
