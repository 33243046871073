import { lazy } from 'react';

const ClientEngagementAgreement = lazy(() =>
  import('./ClientEngagementAgreement')
);
const CustomerService = lazy(() => import('./CustomerService'));
const CsServicesBankAccountChange = lazy(() =>
  import('./CustomerService/components/OnlineServices/BankAccountChange')
);
const CsServicesChangeDeposit = lazy(() =>
  import('./CustomerService/components/OnlineServices/ChangeDeposit')
);
const CsServicesDeposits = lazy(() =>
  import('./CustomerService/components/OnlineServices/Deposits')
);
const CsServicesDocumentUpload = lazy(() =>
  import('./CustomerService/components/OnlineServices/DocumentUpload')
);
const CsServicesEnrollNewDebt = lazy(() =>
  import('./CustomerService/components/OnlineServices/EnrollNewDebt')
);
const CsServicesIncreaseProgramDeposit = lazy(() =>
  import('./CustomerService/components/OnlineServices/IncreaseProgramDeposit')
);
const CsServicesOneTimeDeposit = lazy(() =>
  import('./CustomerService/components/OnlineServices/OneTimeDeposit')
);
const DedicatedAccount = lazy(() => import('./DedicatedAccount'));
const Disclosures = lazy(() => import('./Disclosures'));
const DocumentVault = lazy(() => import('./DocumentVault'));
const EnrolledDebt = lazy(() => import('./EnrolledDebt'));
const EnrolledDebtDetail = lazy(() => import('./EnrolledDebtDetail'));
const Home = lazy(() => import('./Home'));
const NegotiationOverview = lazy(() => import('./NegotiationOverview'));
const Notifications = lazy(() => import('./Notifications'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const ProgramResources = lazy(() => import('./ProgramResources'));
const Settings = lazy(() => import('./Settings'));
const SettingsProgram = lazy(() => import('./Settings/Program'));
const SettingsContactInfo = lazy(() => import('./Settings/ContactInfo'));
const SettingsCommunicationPreferences = lazy(() =>
  import('./Settings/CommunicationPreferences')
);
const SettlementOverview = lazy(() => import('./SettlementOverview'));
const NotFound = lazy(() => import('./NotFound'));

export {
  ClientEngagementAgreement,
  CustomerService,
  CsServicesBankAccountChange,
  CsServicesChangeDeposit,
  CsServicesDeposits,
  CsServicesDocumentUpload,
  CsServicesEnrollNewDebt,
  CsServicesIncreaseProgramDeposit,
  CsServicesOneTimeDeposit,
  DedicatedAccount,
  Disclosures,
  DocumentVault,
  EnrolledDebt,
  EnrolledDebtDetail,
  Home,
  NegotiationOverview,
  Notifications,
  PrivacyPolicy,
  ProgramResources,
  Settings,
  SettingsProgram,
  SettingsContactInfo,
  SettingsCommunicationPreferences,
  SettlementOverview,
  NotFound
};
