import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, useTheme } from '@achieve/sunbeam';

import { useLaunchDarkly } from 'lib/hooks';
import { getUserRecord } from 'shared-selectors/userRecord';
import { storage } from 'lib/utils/storage';

const getDismissed = keyName => storage.get(`${keyName}Dismissed`, false); // Default to false
const setDismissed = keyName => storage.set(`${keyName}Dismissed`, true); // Set to true

// For looking up the feature flag and local storage
const PROMO_KEY_NAME = {
  modal: 'appPromoModal',
  sidebar: 'appPromoSidebar'
};

// This hook is used to determine whether or not a given promo for the FDR App should be rendered
// It's made to be extensible as well since it can ultimately service three different promo scenarios
// controlled by different feature flags.
export function useAppPromo(promoType) {
  const user = useSelector(getUserRecord);
  const [showPromo, setShowPromo] = useState(false);
  const { flags, activeFlagNames } = useLaunchDarkly();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const keyName = PROMO_KEY_NAME[promoType];

  useEffect(() => {
    const featureFlagEnabled =
      flags && flags[activeFlagNames[keyName]] === 'show';
    const isFDR = user.lawFirmId === 1059 || user.lawFirmId === '1059';
    const hasDismissedCTA = getDismissed(keyName);

    switch (keyName) {
      case 'appPromoModal':
        if (featureFlagEnabled && isFDR && !hasDismissedCTA && isMobile) {
          setShowPromo(true);
        } else {
          setShowPromo(false);
        }
        return;
      case 'appPromoSidebar':
        if (featureFlagEnabled && isFDR && isDesktop) {
          setShowPromo(true);
        } else {
          setShowPromo(false);
        }
        return;
      default:
        setShowPromo(false);
        return;
    }
  }, [activeFlagNames, flags, isDesktop, isMobile, keyName, user]);

  return {
    showPromo,
    dismissPromo: () => {
      setShowPromo(false);
      setDismissed(keyName);
    }
  };
}
