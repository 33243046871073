import React from 'react';
import { analyticsTrackEvent } from 'lib/utils/analytics';
import { fetchLaunchDarklyFlags } from 'lib/api';
import { useQuery } from 'react-query';
import { useAuth } from './useAuth';
import { logger } from 'shared-components';

const LOGGING_PREFIX = 'EXPERIMENTATION ::';

/** To add an experiment or feature flag, create an entry here
 or in the FF_KEY_NAMES_FOR_DELAYED_LOAD object below.  */
// These keys will be when the app loads initially.
const FF_KEY_NAMES_FOR_IMMEDIATE_LOAD = {
  aaExperiment: 'cx-aa-dashboard-experimentation',
  clientEdu: 'clx-client-education',
  showProductTour: 'cx-product-tour',
  loadUpscopeScript: 'dashboard-cobrowse',
  loadUpscopeScriptOnboarding: 'cx-onboarding-dashboard-cobrowse',
  arDashboardCobrowse: 'ar-dashboard-cobrowse',
  dashboardHomepageComponentOrder: 'cx-dashboard-homepage-component-order',
  documentVault: 'cx-dashboard-document-vault',
  statementGeneration: 'cx-dashboard-statement-generation',
  cfosDisclosuresUpdate: 'cx-dashboard-cfos-disclosures-update',
  appPromoModal: 'cx-native-app-mobile-cta-modal',
  appPromoSidebar: 'cx-native-app-mobile-cta-sidebar'
};

// These keys must be loaded on demand (i.e. when a user reaches a certain step in a flow)
const FF_KEY_NAMES_FOR_DELAYED_LOAD = {
  programDetails: 'cx-homepage-program-details',
  dashboardPtsDisplayCreditor: 'cx-dashboard-pts-display-creditor',
  ptsMvp: 'cx-pts-on-clients-dashboard-homepage'
};

const KEYS_TO_FETCH_ON_PAGE_LOAD = Object.values(
  FF_KEY_NAMES_FOR_IMMEDIATE_LOAD
);

// Not exporting this directly on purpose, so we can ensure that the only way
// to consume it is through the Provider component.
// More on this pattern here: https://kentcdodds.com/blog/how-to-use-react-context-effectively
const LDContext = React.createContext();

// Processes flag data, caching experiment details and preparing them for use.
const processFlags = (unprocessedFlags = {}) => {
  const len = Object.keys(unprocessedFlags).length;
  if (len === 0) return null;

  // Utility for individual flags, caching experiment data, and destructuring the variation and payloads
  const processFlagValue = flag => {
    _cacheExperimentData(flag.value);
    return { variation: flag?.value?.variation, payload: flag?.value };
  };

  const processedFlags = {}; // The variation of the flag, will be exposed in flags context
  const processedFlagPayloads = {}; // The entire flag payload, will be exposed in flagPayloads context

  Object.entries(unprocessedFlags).forEach(([k, v]) => {
    const processedFlag = processFlagValue(v);
    processedFlags[k] = processedFlag.variation;
    processedFlagPayloads[k] = processedFlag.payload;
    // Only fire off analytics for feature flags that are actually present in Launch Darkly
    if (processedFlags[k] !== undefined) {
      const { experimentName } = v.value;
      // NOTE FROM STEVE: Tracking analytics so we can communicate to EH when an experiment is loaded
      // Doing this here, because we can't rely on the experiments array to be populated
      // before the pageView is tracked. This is because we have to make a network call to get
      // the experiment data, and it doesn't reliably return before the pageView is tracked.
      analyticsTrackEvent(
        {
          eventCategory: 'LaunchDarkly',
          eventAction: `${experimentName} Feature Flag Loaded`
        },
        'Launch Darkly Flag Loaded'
      );
      logger.debug(
        `${LOGGING_PREFIX} (${experimentName}) :: (${processedFlags[k]})`
      );
    }
  });

  return { processedFlags, processedFlagPayloads };
};

function LaunchDarklyProvider({ children }) {
  const { isAuthenticated } = useAuth();
  const [flags, setFlags] = React.useState(null); // The variation of the flag
  const [flagPayloads, setFlagPayloads] = React.useState(null); // The entire flag payload

  // Fetches individual flags as needed, beyond the initial load.
  const fetchSingleFlag = React.useCallback(
    async (flagKey, customAttributes) => {
      // Don't fetch flags if the user isn't authenticated as there won't be a valid token
      if (!isAuthenticated) return;
      try {
        logger.info(
          `${LOGGING_PREFIX} Logging custom attributes`,
          customAttributes
        );
        const featureFlags = await fetchLaunchDarklyFlags(
          [flagKey],
          customAttributes
        );
        const { processedFlags, processedFlagPayloads } = processFlags(
          featureFlags
        );
        setFlags(prevFlags => {
          return { ...prevFlags, ...processedFlags };
        });
        setFlagPayloads(prevFlagPayloads => {
          return { ...prevFlagPayloads, ...processedFlagPayloads };
        });
      } catch (e) {
        logger.error(`${LOGGING_PREFIX} Error fetching flag ${flagKey}`, e);
      }
    },
    [isAuthenticated]
  );

  // Combine immediate and delayed load keys for complete flag management.
  const activeFlagNames = React.useMemo(
    () => ({
      ...FF_KEY_NAMES_FOR_IMMEDIATE_LOAD,
      ...FF_KEY_NAMES_FOR_DELAYED_LOAD
    }),
    []
  );

  // Manages the lifecycle of the upfront feature flag fetch.
  const { error } = useQuery({
    queryKey: ['upfrontFeatureFlags'],
    queryFn: async () => {
      const featureFlags = await fetchLaunchDarklyFlags(
        KEYS_TO_FETCH_ON_PAGE_LOAD
      );
      const { processedFlags, processedFlagPayloads } = processFlags(
        featureFlags
      );

      setFlags(processedFlags);
      setFlagPayloads(processedFlagPayloads);
    },
    staleTime: Infinity,
    // Don't run query until user is authenticated and we have an auth0 token
    enabled: !!isAuthenticated
  });

  if (error) {
    logger.error(`${LOGGING_PREFIX} Error loading LD flags`, error);
  }

  const value = {
    activeFlagNames,
    fetchSingleFlag,
    flagPayloads,
    flags
  };

  return <LDContext.Provider value={value}>{children}</LDContext.Provider>;
}

const useLaunchDarkly = () => {
  const context = React.useContext(LDContext);
  if (context === undefined) {
    throw new Error(
      'useLaunchDarkly must be used within a LaunchDarklyProvider'
    );
  }

  return context;
};

// temporarily exposes processFlags for enabled manual calls to
// update experiments array
export { LaunchDarklyProvider, processFlags, useLaunchDarkly };

const getExistingExperiments = () => {
  const existingExperiments = sessionStorage.getItem('experiments');
  if (existingExperiments) {
    return JSON.parse(existingExperiments);
  }
  return [];
};

// When we're dealing with an experiment, we're caching the experiment data in Session Storage
// so we can access it later when we're tracking analytics. This is kind of imperfect, but it's
// the most straightforward way to pass data to analytics without having to refactor parts of the useAnalytics hook
// to be able to store the experiment data in memory.
const _cacheExperimentData = experimentPayload => {
  const { experimentId, experimentName, variation } = experimentPayload;
  const cacheObj = {
    experiment_name: experimentName,
    experiment_id: experimentId,
    variation
  };

  const existingExperiments = getExistingExperiments();
  const experimentAlreadyCached = existingExperiments.find(
    v => v.experiment_id === experimentId
  );

  if (experimentAlreadyCached) {
    // Don't do anything
    return null;
  } else {
    // Add the new experiment to the cache
    existingExperiments.push(cacheObj);
  }

  sessionStorage.setItem('experiments', JSON.stringify(existingExperiments));
};
