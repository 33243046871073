import { lazy } from 'react';

const Main = lazy(() => import('./Main'));
const HowItWorks = lazy(() => import('./HowItWorks'));
const CreditorCalls = lazy(() => import('./CreditorCalls'));
const Videos = lazy(() => import('./Videos'));
const FAQPage = lazy(() => import('./FAQ'));
const ProgramGlossary = lazy(() => import('./ProgramGlossary'));
const HelpfulDocuments = lazy(() => import('./HelpfulDocuments'));
const Blog = lazy(() => import('./Blog'));
const CreditImpact = lazy(() => import('./CreditImpact'));
const LegacyContentRedirect = lazy(() => import('./LegacyRedirect'));

export {
  Main,
  HowItWorks,
  CreditorCalls,
  Videos,
  FAQPage,
  ProgramGlossary,
  HelpfulDocuments,
  Blog,
  CreditImpact,
  LegacyContentRedirect
};
