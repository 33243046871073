import React from 'react';
import { Box, Button, Dialog, Typography } from '@achieve/sunbeam';
import { CheckCircle } from 'react-feather';

import { analyticsTrackEvent } from 'lib/utils/analytics';
import { useTranslation, useAppPromo } from 'lib/hooks';

const APP_DOWNLOAD_URL = 'https://freedomdebtrelief.onelink.me/YHp1/nsar320u';
const APP_PROMO_IMAGE_URL =
  'https://images.ctfassets.net/b32zuu6bt176/6Admtof8viz9yQzbe7HvpS/f9885dc34b343e78b438913fefdd6f6b/FDR_app_promotion_full.png';

const handleAppCTAInteraction = actionType => {
  analyticsTrackEvent(
    {
      category: 'native_app_cta',
      action: actionType,
      label: 'native_app_cta_modal_interaction'
    },
    // Uppercasing first letter for formatting
    `Native App Download CTA ${actionType.charAt(0).toUpperCase() +
      actionType.slice(1)}`
  );
};

export const Modal = () => {
  const { showPromo, dismissPromo } = useAppPromo('modal');
  const { t } = useTranslation();

  return (
    <Box data-testid="native-app-cta-modal">
      {/*** Adding z-index to prevent conflicts with Product Tour. This should appear above everything. */}
      <Dialog open={showPromo} sx={{ zIndex: 111111 }}>
        <Box display="flex" flexDirection="column" gap={3} padding="24px">
          <AppPromoBody />
          <Button
            data-testid="native-app-cta-download-button"
            variant="contained"
            href={APP_DOWNLOAD_URL}
            onClick={() => {
              handleAppCTAInteraction('download');
              dismissPromo();
            }}
          >
            <Typography variant="ascendLabelMd">
              {t('appPromo.getTheApp')}
            </Typography>
          </Button>
          <Button
            data-testid="native-app-cta-dismiss-button"
            variant="text"
            onClick={() => {
              handleAppCTAInteraction('dismiss');
              dismissPromo();
            }}
          >
            <Typography variant="ascendLabelMd">
              {t('appPromo.remindMeLater')}
            </Typography>
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

export function AppPromoBody() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      data-testid="native-app-cta-body"
    >
      <Typography component="h2" variant="ascendHeadingSm">
        {t('appPromo.stayInTheKnowOnTheGo')}
      </Typography>
      <Box>
        <img
          width="100%"
          src={APP_PROMO_IMAGE_URL}
          alt="Rendering of the FDR App on an iPhone"
        />
      </Box>
      <Typography component="p" variant="ascendBodySm">
        {t('appPromo.keepTrackOfEveryDebt')}
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" gap={'8px'}>
          <Box display="flex" alignItems="center">
            <CheckCircle size={24} />
          </Box>
          <Typography component="p" variant="ascendBodySm">
            {t('appPromo.instantlyApproveSettlements')}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap={'8px'}>
          <Box display="flex" alignItems="center">
            <CheckCircle size={24} />
          </Box>
          <Typography component="p" variant="ascendBodySm">
            {t('appPromo.viewRealTimeProgramUpdates')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Modal;
